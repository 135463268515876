<template>
  <div class="container flex-column">
    <nav-box :title="title" :back="true"></nav-box>
    <div class="content flex-column">
      <div class="ticket flex-column">
        <div class="ticket-message flex">
          <span class="ticket-name">{{ couponMessage.itemPackage.name }}</span>
          <p class="ticket-price">￥{{ couponMessage.itemPackage.price }}</p>
        </div>
        <div class="flex">
          <div class="label">可用数量：{{ couponMessage.quantity }}张</div>
        </div>
      </div>
      <div class="title">请选择手牌</div>
      <div class="card-list flex-column">
        <blockquote v-for="(item, index) in cardList" :key="index">
          <div class="card-item flex flex-sb on" @click="checkedCard(item)">
            <div class="flex">
              <span>手牌号：</span>
              <p>{{ item.keyCardCode }}</p>
            </div>
            <div class="flex">
              <span>入场时间：</span>
              <p>{{ item.entryTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}</p>
            </div>
            <div class="flex">
              <span>消费金额：</span>
              <p class="text-price">{{ item.standardAmount }}</p>
              <img
                v-if="item.checked"
                src="../../../assets/checked.png"
                alt=""
              />
              <img
                v-if="!item.checked"
                src="../../../assets/check.png"
                alt=""
              />
            </div>
          </div>
        </blockquote>
      </div>
      <div class="number flex flex-center">
        <span>本次使用：</span>
        <p>{{ checkedLength }}张</p>
      </div>
      <div class="btn-group flex flex-sb">
        <div class="cancel flex flex-center" @click="cancel">取消</div>
        <div class="save flex flex-center" @click="affirm">确认核销</div>
      </div>
    </div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
import { Toast } from "vant";
import debounce from "@/utils/debounce.js";
import axios from "axios";
import moment from "moment";
import { swallowKeyNo,spitoutKeyNo } from "@/utils/recycler";
export default {
  components: {
    navBox,
  },
  name: "verification",
  data() {
    return {
      title: "券核销",
      couponCode: "",
      thirdType: 0,
      cardList: [],
      couponMessage: {
        quantity: 0,
        itemPackage: {
          name: "",
          price: 0,
        },
      }, //券信息
      checkedLength: 0,
      departureData: [],
      departure: false,
      isPrepayment: localStorage.getItem("type") == "先付模式" ? true : false, //是否先付模式
      isRecycler:false,// 自助机程序是否启用手牌回收箱
      targetMemberCardId:null,//返现会员ID
      targetMemberCardNo:null,//返现会员卡号
    };
  },
  created() {
    this.thirdType = this.$route.query.thirdType;
    this.couponCode = this.$route.query.couponCode;
    this.departure = this.$route.query.departure;
    this.targetMemberCardId = this.$route.query.targetMemberCardId;
    this.targetMemberCardNo = this.$route.query.targetMemberCardNo;
    this.departureData = JSON.parse(localStorage.getItem("departureData")) || [];
  },
  mounted() {
    this.initData();
    // 自助机程序是否启用手牌回收箱
    this.getRecycler()
  },
  filters: {
    dateFormat: function (dateStr, pattern = "") {
      if (!dateStr) {
        return "-";
      }
      return moment(dateStr).format(pattern);
    },
  },
  methods: {
    // 初始化数据
    async initData() {
      await this.getCouponCode();
    },
    // 获取券对应的套餐
    getCouponCode() {
      this.$fetch(this.Api.ticket, {
        couponCode: this.couponCode,
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
        thirdType: this.thirdType,
      }).then((res) => {
        this.couponMessage = res.data;
        this.getOrder();
      });
    },
    // 选中要核销的手牌
    checkedCard(item) {
      item.checked = !item.checked;
      let arr = this.cardList.filter((item) => item.checked);
      this.checkedLength = arr.length;
      if (arr.length > this.couponMessage.quantity) {
        this.$toast("选择要核销的手牌数不能大于券的数量");
        item.checked = false;
        this.checkedLength = this.couponMessage.quantity;
        return false;
      }
    },
    affirm(){
      // this.PrintReport('f73294d4c4c14ebd9c9de81b9caa1472');
      debounce(this.debounceAffirm)
    },
    // 确认核销
    debounceAffirm() {
      let arr = this.cardList
        .filter((item) => item.checked)
        .map((ite) => {
          return ite.consumptionSubjectId;
        });
      let couponCode = this.couponCode;
      if (arr.length == 0) {
        this.$toast("请选择要核销的手牌号");
      }
      if (this.thirdType == 4) {
        couponCode =
          this.couponMessage.verify_token +
          "|" +
          this.couponMessage.encrypted_code +
          "|" +
          this.couponMessage.sku_id +
          "|" +
          this.couponMessage.pay_amount;
      }
      if (this.thirdType == 16) {
        couponCode =
          this.couponMessage.verify_token +
          "|" +
          this.couponMessage.encrypted_code;
      }
      var data = {
        consumptionSubjectIdArray: arr,
        couponCode: couponCode,
        thirdType: Number(this.thirdType),
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
        description: "自助机核销",
        quantity: this.couponMessage.quantity,
      };
      this.$post(this.Api.ticket2, data)
        .then((res) => {
          if (res.code == 1) {
            this.getOrder(false);
          }
        })
        .catch((err) => {
          Toast(err.response.data.message);
        });
    },
    // 调单
    getOrder(first = true) {
      let consumptionAreaId = localStorage.getItem("consumptionAreaId");
      let consuptionSubjectIds = localStorage.getItem("consuptionSubjectIds").split(",");
      var data = {
        consumptionAreaId: consumptionAreaId,
        consuptionSubjectIds:consuptionSubjectIds,
        settlementType: 1,
        real: false,
      };
      var that = this;
      // 调单
      that.$post(that.Api.transferOrder, data).then(async (res) => {
        // 如果是刚进来调单
        if (first) {
          that.cardList = that
            .getFlatArr(res.data.details)
            .filter((item) => item.status != 10000);
          that.cardList.forEach((item) => {
            // 新增选中属性
            that.$set(item, "checked", false);
          });
          // 根据有多少张券选中多少个手牌

          for (let i = 0; i < that.couponMessage.quantity; i++) {
            that.cardList.forEach((item, index) => {
              if (index == i) {
                item.checked = true;
              }
            });
          }
          let arr = that.cardList.filter((item) => item.checked);
          console.log(arr);
          that.checkedLength = arr.length;
        } else {
          // 否则判断是否结账
          if (res.data.unPaidAmount === 0) {
            if (that.isPrepayment) {//预付模式只打印手牌
              
              let requestData = await this.requestUrlOrBody({type: "P10"});
              // 打印多张入场单
              that.cardList.forEach((item, index) => {
                setTimeout(() => {
                  that.dataSet(requestData.body, [{KeyNo:item.keyCardCode}]);
                }, 3000 * (index)); // 每隔1.5秒执行一次
              });
              
            } else {
              if(that.departure){
                that.settleAccount(res.data.details, res.data.id);
                return true
              }

              data.real = true;
              data.targetMemberCardId=that.targetMemberCardId
              data.targetMemberCardNo=that.targetMemberCardNo
              // 结账
              that.$post(that.Api.transferOrder, data)
                .then((res) => {
                  // 结账成功
                  if (res.code == 1) {

                    Toast.success("结账成功");
                    Toast.loading({
                      message: "单据打印中，请稍后....",
                      forbidClick: true,
                      duration: 1500,
                    });

                    var arr = [];
                    // 如果勾选了买单不离场
                    if (this.departure) {
                      // 获取离场的手牌 打印离场单
                      arr = that.departureData
                        .filter((item) => !item.checked)
                        .map((item) => {
                          return {
                            KeyNo: item.keyCardCode,
                          };
                        });
                      //吐卡
                      if(this.isRecycler){
                        spitoutKeyNo(localStorage.getItem("keyCard"))
                      }
                    } else {
                      // 获取离场手牌打印离场单
                      var list = that.getFlatArr(res.data.details);
                      arr = list.map((item) => {
                        return {
                          KeyNo: item.keyCardCode,
                          consumptionSubjectId:item.consumptionSubjectId
                        };
                      });
                      //结账吞卡
                      if(this.isRecycler){
                        swallowKeyNo(localStorage.getItem("keyCard"))
                      }
                    }

                    that.PrintReport(res.data.id);
                    
                    setTimeout(() => {
                      that.printShoes(
                        res.data.needSendShoeCabinetNotifySubjectIds
                      );
                    }, 400);
                    setTimeout(() => {
                      that.printDeparture(arr);
                    }, 1500);

                    
                  }
                })
                .catch((err) => {
                  // 结账失败 重新调单
                  Toast(err.response.data.message);
                  that.$router.push({
                    path: "/list",
                    query: {
                      targetMemberCardId: that.targetMemberCardId,
                      targetMemberCardNo: that.targetMemberCardNo
                    },
                  });
                });
            }

            setTimeout(() => {
              that.$router.push({
                path: "/result",
                query: {
                  payType: "ticket",
                  result: true,
                },
              });
            }, 1500);

          } else {
            that.$router.push({
              path: "/list",
              query: {
                targetMemberCardId: that.targetMemberCardId,
                targetMemberCardNo: that.targetMemberCardNo
              },
            });
          }
        }
      });
    },
    // 结账和不离场
    settleAccount(list, orderId) {
      let that = this;
      // 订单手牌消费主体id列表
      let items = list.map((item) => item.consumptionSubjectId);
      // 订单手牌号列表
      let codesList = list.map((item) => item.keyCardCode);
      // 买单不离场手牌消费主体id列表
      let reReceptionconsumptionSubjectId = that.departureData
        .filter((item) => item.checked)
        .map((item) => item.consumptionSubjectId);
      let formData = {
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
        consumptionSubjectId: items,
        settlementType: 1,
        description: "",
        orderId: orderId,
        removedCertificateCodes: [],
        srcCertificateCodes: codesList, //结账手牌号
        terminalId: localStorage.getItem("terminalId")
          ? localStorage.getItem("terminalId")
          : "9",
      };
      if (that.isPrepayment) {
        formData.reReceptionconsumptionSubjectId = items;
      } else if (that.departure) {
        formData.reReceptionconsumptionSubjectId = reReceptionconsumptionSubjectId;
      } else {
        formData.reReceptionconsumptionSubjectId = [];
      }
      Toast.loading({
        message: "正在结账...",
        forbidClick: true,
      });
      that.$post(that.Api.NotLeave, formData)
        .then(async (res) => {
          Toast.clear();

          that.$toast.loading({
            message: "正在打印中，请稍后....",
            forbidClick: true,
            duration: 3000,
          });

          setTimeout(() => {
            that.PrintReport(res.data.id);
          }, 1500);
          
          // let cardList = res.data.details
          //   .filter((item) => item.status != 10000)
          //   .map((item) => {
          //     return {
          //       keyCardCode: item.keyCardCode,
          //     };
          //   });

          var cardList = [];
          // 如果勾选了买单不离场
          if (this.departure) {
            // 获取离场的手牌 打印离场单
            cardList = that.departureData
              .filter((item) => !item.checked)
              .map((item) => {
                return {
                  keyCardCode: item.keyCardCode,
                };
              });

              //结账吐卡
              if(this.isRecycler){
                spitoutKeyNo(localStorage.getItem("keyCard"))
              }

          } else {
            // 获取离场手牌打印离场单
            var list = that.getFlatArr(res.data.details);
            cardList = list.map((item) => {
              return {
                keyCardCode: item.keyCardCode,
              };
            });

            //结账吞卡
            if(this.isRecycler){
              swallowKeyNo(localStorage.getItem("keyCard"))
            }
          }

          if (typeof Android2JS != "undefined") {
            cardList.forEach((item) => {
              let storeName = localStorage.getItem("storeName");
              Android2JS.Print3Lines();
              Android2JS.PrintText(storeName, 48, true);
              Android2JS.PrintText("", true);
              Android2JS.PrintText("手牌号：" + item.keyCardCode, 36, true);
              Android2JS.PrintText("打印时间：" + moment().format("YYYY/MM/DD HH:mm:ss"),32);
              Android2JS.PrintQrcode(item, 15);
              Android2JS.Print3Lines();
              Android2JS.CutPaper();
            });
          } else {
            // that.$toast("未检测到打印硬件，已跳过打印");
            let requestData = await this.requestUrlOrBody({type: "P08"});
            axios.post(requestData.requestUrl,{consumptionSubjectIdArray: list.map(item=>item.consumptionSubjectId)},
              {
                headers: {
                  access_token: localStorage.getItem("token"),
                  x_req_application_id: 1,
                  x_req_terminal: 1,
                  x_req_store_id: localStorage.getItem("storeId"),
                },
              }
            )
            .then(function (res) {
              // 打印多张离场单
              res.data.data.forEach((item, index) => {
                setTimeout(() => {
                  that.dataSet(requestData.body, [item]);
                  console.log(item.KeyNo); // 输出检查
                }, 3000 * (index)); // 每隔1.5秒执行一次
              });
            })
            .catch(function (error) {});

          }

          setTimeout(() => {
            that.$router.push({
              path: "/result",
              query: {
                payType: "ticket",
                result: true,
              },
            });
          }, 1500);
        })
        .catch((err) => {
          // 结账失败 重新调单
          Toast(err.response.data.message);
          that.$router.push({
            path: "/list",
            query: {
              targetMemberCardId: that.targetMemberCardId,
              targetMemberCardNo: that.targetMemberCardNo
            },
          });
        });
    },
    cancel() {
      this.$router.push({
        path: "/list",
        query: {
          targetMemberCardId: this.targetMemberCardId,
          targetMemberCardNo: this.targetMemberCardNo
        },
      });
    },
    // 重构数组
    getFlatArr(array) {
      return array.reduce((a, item) => {
        let flatArr = [...a, item];
        if (item.children) {
          flatArr = [...flatArr, ...this.getFlatArr(item.children)];
        }
        return flatArr;
      }, []);
    },

    removeBase64Prefix(base64String) {
      return base64String.replace(/^data:.*?base64,/, "");
    },
    // 搜索报表
    dataSet(body, data) {
      var that = this;
      var num = data.printCount ? data.printCount : 0;
      var invoiceAddressValue = data.invoiceAddress ? data.invoiceAddress : "";
      //创建报表对象
      var report = new window.Stimulsoft.Report.StiReport();
      // 加载文件
      report.load(body);
      //新建数据源
      var dataSet = new Stimulsoft.System.Data.DataSet("SimpleDataSet");
      //数据源填充数据
      delete data.printCount;
      delete data.invoiceAddress;
      dataSet.readJson(JSON.stringify(data));
      //给报表的数据源填充数据

      let storeName = localStorage.getItem("storeName") || "云水疗";
      report.regData(dataSet.dataSetName, storeName, dataSet);
      var variableString = report.dictionary.variables.getByName("StoreName");
      variableString.value = storeName;
      if (num) {
        var printCount = report.dictionary.variables.getByName("printCount");
        printCount.value = num;
      }
      if (invoiceAddressValue) {
        var invoiceAddress =
          report.dictionary.variables.getByName("invoiceAddress");
        invoiceAddress.value = invoiceAddressValue;
      }

      // report.regBusinessObject("StoreName", "酒店名称");
      // 创建报表
      viewer.report = report;
      report.renderAsync(function () {
        if (typeof Android2JS != "undefined") {
          // 当报告渲染完成后导出为PNG图片
          report.exportDocumentAsync(function (htmlString) {
            // Write HTML text to DIV element.
            var container = document.getElementById("htmlContainer");
            container.innerHTML = htmlString;
            html2canvas(document.getElementById("htmlContainer")).then(
              (canvas) => {
                // 创建一个新的canvas来调整图像尺寸
                var ctx = canvas.getContext("2d");
                var canvasCopy = document.createElement("canvas");
                var copyContext = canvasCopy.getContext("2d");

                // 设置新canvas的尺寸
                canvasCopy.width = 576;
                canvasCopy.height = (canvas.height * 576) / canvas.width;

                // 使用drawImage方法按比例缩放原始canvas内容到新的canvas
                copyContext.drawImage(
                  canvas,
                  0,
                  0,
                  canvas.width,
                  canvas.height,
                  0,
                  0,
                  canvasCopy.width,
                  canvasCopy.height
                );
                var imgData = that.removeBase64Prefix(
                  canvasCopy.toDataURL("image/png")
                );
                Android2JS.Print3Lines();
                Android2JS.PrintBitmap(imgData);
                Android2JS.Print3Lines();
                Android2JS.CutPaper();
              }
            );
          }, Stimulsoft.Report.StiExportFormat.Html);
        } else {
          report.exportDocumentAsync(function (htmlString) {
            console.log(htmlString)
          }, Stimulsoft.Report.StiExportFormat.Html);

          // that.$toast("未检测到打印硬件，已跳过打印");
          report.print();
        }

        
      });
    },
    // 封装请求  返回报表请求地址和body
    // 传入获取报表类型如   {type: "P01"},
    async requestUrlOrBody(data) {
      var requestData = {
        requestUrl: "",
        body: "",
      };
      let printData = await this.$fetchNo(
        this.Api.PrintReport + "/" + data.type
      );
      requestData.body = printData.data.body;
      requestData.requestUrl = printData.data.dataInterfaceUrl;
      let reportData = await this.$fetchNo(this.Api.ReportFormat, {
        reportId: printData.data.id,
      });
      if (reportData.data.items.length > 0) {
        let list = reportData.data.items.filter((item) => item.isDefault);
        if (list.length > 0) {
          requestData.body = list[0].body;
        }
      }
      return requestData;
    },
    // 请求报表单据打印
    axiosPrint(requestData, formData) {
      axios
        .post(
          requestData.requestUrl,
          {
            ...formData,
          },
          {
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          }
        )
        .then(function (res) {
          that.dataSet(requestData.body, res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 打印结账单
    async PrintReport(orderId) {
      //获取打印报表
      let data = {
        type: "P09",
      };
      let requestData = await this.requestUrlOrBody(data);
      let formData = {
        orderId: orderId,
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
      };
      var that = this;
      axios
        .post(
          requestData.requestUrl,
          {
            ...formData,
          },
          {
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          }
        )
        .then(function (res) {
          console.log('打印结账单',requestData,res)
          that.dataSet(requestData.body, res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 打印离场单
    async printDeparture(list) {
      console.log(list);
      let res = await this.$fetchNo(this.Api.getSettingCode + "/001002010");
      if (
        res &&
        res.data &&
        res.data.value &&
        !isNaN(res.data.value) &&
        parseInt(res.data.value) > 0
      ) {
        //获取打印报表
        let data = {
          // id:this.orderData.id,
          type: "P08",
        };
        let that = this;
        let requestData = await this.requestUrlOrBody(data);
        axios.post(requestData.requestUrl,{consumptionSubjectIdArray: list.map(item=>item.consumptionSubjectId)},
          {
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          }
        )
        .then(function (res) {
          // that.dataSet(requestData.body, res.data.data);
          // 打印多张离场单
          res.data.data.forEach((item, index) => {
            setTimeout(() => {
              that.dataSet(requestData.body, [item]);
              console.log(item.KeyNo); // 输出检查
            }, 3000 * (index)); // 每隔1.5秒执行一次
          });
        })
        .catch(function (error) {});
      }
    },
    // 打印鞋吧
    async printShoes(cashierIds) {
      let res = await this.$fetchNo(this.Api.getSettingCode + "/001002008");
      if (
        res &&
        res.data &&
        res.data.value &&
        !isNaN(res.data.value) &&
        parseInt(res.data.value) > 0
      ) {
        //获取打印报表
        let data = {
          // type: "P05",
        };
        let that = this;
        let requestData = await this.requestUrlOrBody(data);
        axios
          .get(requestData.requestUrl, {
            params: {
              consumptionSubjectIds: cashierIds.join(","),
            },
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          })
          .then(function (res) {
            that.dataSet(requestData.body, res.data.data);
          })
          .catch(function (error) {});
      }
    },
    // 自助机程序是否启用手牌回收箱
    getRecycler() {
      this.$fetchNo(this.Api.getSettingCode + "/001006005").then((res) => {
        this.isRecycler = (res.data.value == "1" && localStorage.getItem("type") == "自助结账") ? true : false;
        console.log('this.isRecycler==',this.isRecycler);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.container {
  height: 100vh;
}
.content {
  padding: 44px;
  height: calc(100% - 200px);
  box-sizing: border-box;
  .ticket {
    width: 100%;
    box-sizing: border-box;
    height: 312px;
    background-image: url("../../../assets/ticket-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 312px;
    padding: 34px 78px;
    justify-content: center;
    margin-bottom: 64px;
    .ticket-message {
      font-size: 32px;
      font-weight: bold;
      color: #1a1e22;
      line-height: 46px;
      margin-bottom: 20px;
      .ticket-name {
        flex: 1;
        margin-right: 64px;
      }
    }
    .label {
      padding: 0 17px;
      height: 56px;
      background-color: #fff7e8;
      border-radius: 4px;
      font-size: 24px;
      color: #fb7214;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    font-size: 30px;
    color: #1a1e22;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .card-list {
    width: 100%;
    flex: 1;
    overflow: auto;
    margin-bottom: 24px;
    .card-item {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      height: 85px;
      border-radius: 16px;
      background-color: #e7f3ff;
      border: 2px solid #e7f3ff;
      align-items: center;
      box-sizing: border-box;
      padding: 0 24px;
      margin-bottom: 24px;
      &.on {
        border: 2px solid #007aff;
      }
      div {
        font-size: 22px;
        color: rgba(26, 30, 34, 0.5);
        align-items: center;
        p {
          color: #1a1e22;
        }
        img {
          width: 40px;
          height: 40px;
          margin-left: 33px;
        }
      }
    }
  }
  .number {
    width: 100%;
    height: 96px;
    background-color: #222c3a;
    border-radius: 16px;
    margin-bottom: 16px;
    font-size: 30px;
    color: #b9bdc6;
    p {
      color: #ffffff;
    }
  }
  .btn-group {
    width: 100%;
    height: 96px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0 24px;
    box-sizing: border-box;
    align-items: center;
    .cancel {
      width: 290px;
      height: 70px;
      border-radius: 16px;
      background-color: #e7f3ff;
      font-size: 30px;
      color: #0068f0;
      font-weight: bold;
    }
    .save {
      flex: 1;
      margin-left: 24px;
      height: 70px;
      border-radius: 16px;
      font-size: 30px;
      color: #ffffff;
      font-weight: bold;
      background-color: #0068f0;
    }
  }
}
</style>